@import "scss/variables";

.header {
  background: $header-bg;
  color: $header-font-color;
  position: sticky;
  background: #fff;
  z-index: 200;
  top: 0;

  .first_section {
    @media(min-width: 991px) {
      display: none;
    }

    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 70px;

    .mobile-header__menu-button,
    .language-button {
      display: none;
    }
  }

  @media(min-width: 991px) {
    .header_mobile_search {
      display: none;
    }
  }

  .second_section {
    .logo_div {
      min-width: 200px;
      height: 100%;
      display: flex;
      align-items: center;

      img {
        object-fit: contain;
        object-position: left;

        @media only screen and (max-width: 991px) {
          object-position: center;
        }
      }
    }

    .header_nav_menu {
      gap: 42px;
      width: 100%;
      display: flex;
      max-width: 600px;
      margin-left: 30px;
      align-items: center;
      justify-content: flex-start;

      @media (max-width: 991px) {
        display: none;
      }

      .header_nav_menu_item {
        height: 100%;
        cursor: pointer;

        .menu__submenu {
          max-height: 368px;
          overflow-y: auto;
          overflow-x: hidden;
        }

        a {
          height: 100%;
          display: flex;
          color: #303030;
          font-size: 15px;
          align-items: center;
          white-space: nowrap;

          &:hover {
            color: $main-theme-color-light;
          }

          svg {
            fill: $nav-links-item-default-arrow-color;
            top: calc(50% - 3px);
            margin-left: 6px;
          }
        }

        .menu__submenu {
          z-index: 11;
          transform-origin: top;
          background: #fff;
          box-shadow: 0 1px 15px rgba(0, 0, 0, .25);
          color: $menu-font-color;
          background: $menu-bg;
          list-style: none;
          position: absolute;
          width: 300px;
          padding: 3px 0;
          font-size: 14px;
          line-height: 16px;
          font-weight: $font-weight-medium;
          display: none;

          .menu__li {
            display: flex;
            position: relative;
            flex-direction: column;

            .menu_item__container {
              gap: 6px;
              width: 100%;
              margin: 1px 0;
              display: flex;
              min-height: 34px;
              padding: 5px 15px;
              align-items: center;
              justify-content: space-between;

              .link__button {
                width: 100%;
                background: unset;
              }

              .only__link {
                width: max-content !important;
              }

              .menu__li__a {
                border: none;
                display: flex;
                text-align: left;
                white-space: unset;
                align-items: center;
                font-weight: inherit;
                word-wrap: break-word;
                color: $body-font-color;
                background: transparent;
                justify-content: space-between;

                &:hover {
                  text-decoration: underline;
                }
              }

              .wrapper__bttn {
                //width: 100%;
                display: flex;
                cursor: pointer;
                min-width: 50px;
                align-items: center;
                background-color: unset;
                justify-content: flex-end;

                svg {
                  transition: transform .2s;
                }
              }

              .closed {
                svg {
                  transform: rotate(180deg);
                  transition: transform 0.2s;
                }
              }

              &:hover {
                background: #f2f2f2;
              }
            }

            .menu__submenu {
              top: 0;
              left: 0;
              width: 100%;
              box-shadow: none;
              position: inherit;
              padding-left: 6px;
            }

            & > .menu__submenu {
              //display: block;
              transform: rotateY(0deg);
              animation: fadeIn .2s;
            }
          }
        }

        .deep__submenu {
          display: none;

          .menu__submenu {
            overflow: hidden;
            max-height: unset;
            display: block !important;
            transform: unset !important;
          }
        }

        .opened__drop {
          display: block;
        }

        &:hover {
          & > a {
            color: $main-theme-color-light;
          }

          & > .menu__submenu {
            display: block;
            border-radius: 4px;
            animation: fadeIn .2s;
            transform: rotateX(0deg);

            &::-webkit-scrollbar-thumb {
              border-radius: 4px;
            }
          }

          @keyframes fadeIn {
            from {
              opacity: 0;
            }

            to {
              opacity: 1;
            }
          }
        }
      }

      .dropMenus__container {
        display: flex;
        position: relative;

        .wrapper__bttn {
          cursor: pointer;
          background-color: unset;

          svg {
            transition: transform 0.2s;
          }
        }

        .closed {
          svg {
            transform: rotate(180deg);
            transition: transform 0.2s;
          }
        }

        .dropMenus__body {
          display: none;
          right: 0;
          top: 24px;
          z-index: 11;
          width: 300px;
          padding: 3px 0;
          overflow-y: auto;
          max-height: 368px;
          position: absolute;
          overflow-x: hidden;
          border-radius: 4px;
          flex-direction: column;
          background-color: #ffffff;
          box-shadow: 0 1px 15px rgba(0, 0, 0, 0.25);

          .header_nav_menu_item {
            cursor: pointer;

            .menu__submenu {
              position: unset;
              overflow: unset;
              box-shadow: unset;
              max-height: unset;
            }

            .menu_item__container {
              gap: 6px;
              padding: 5px 15px;
              margin: 1px 0;
              width: 100%;
              display: flex;
              min-height: 34px;
              align-items: center;
              justify-content: space-between;

              .link__button {
                width: 100%;
                background: unset;
              }

              .only__link {
                width: max-content !important;
              }

              a {
                border: none;
                display: flex;
                color: #121212;
                white-space: unset;
                align-items: center;
                font-weight: inherit;
                background: transparent;
                justify-content: space-between;

                &:hover {
                  text-decoration: underline;
                }
              }

              &:hover {
                background-color: #f2f2f2;
              }

              .wrapper__bttn {
                display: flex;
                cursor: pointer;
                min-width: 50px;
                align-items: center;
                background-color: unset;
                justify-content: flex-end;

                svg {
                  transition: transform 0.2s;
                }
              }

              .closed {
                svg {
                  transform: rotate(180deg);
                  transition: transform 0.2s;
                }
              }
            }

            .menu__submenu {
              width: 100%;
              padding-left: 6px;
              opacity: 1 !important;
              display: block !important;
            }
          }
        }

        .dropMenus__body::-webkit-scrollbar-thumb {
          border-radius: 4px;
        }

        .opened__drop {
          display: flex;
        }
      }
    }

    .site-header__search {
      width: auto;
    }

    .nav-panel {
      height: 64px;
      font-size: 14px;
      color: $nav-panel-font-color !important;

      @media(max-width: 991px) {
        height: unset;
      }

      .nav-panel__container {
        height: 100%;

        .nav-panel__row {
          height: 100%;
          display: flex;
          position: relative;
          align-items: center;
          justify-content: space-between;

          .logo_div {
            @media only screen and (max-width: 991px) {
              display: none;
            }
          }

          .nav-panel__departments {
            width: auto;
            display: flex;
            flex-shrink: 0;
            max-width: 500px;
            flex-grow: unset;
            margin-left: 30px;
            justify-content: flex-start;

            .departments {
              height: 60px;
              color: $departments-font-color;
              position: relative;

              .departments__body {
                width: 100%;
                position: absolute;
                color: #333333;
                top: 100%;

                .departments__links-wrapper {
                  overflow: hidden;
                  opacity: 0;

                  .departments__links {
                    display: none;
                    background-color: #fff;
                    box-shadow: 0 0 10px rgb(0 0 0 / 50%);
                    position: absolute;
                    width: 100%;
                    list-style: none;
                    border-radius: 4px;
                    max-height: 425px;

                    &,
                    .menu {
                      .departments__item__li {
                        position: relative;

                        .departments__item__li_a {
                          font-weight: $departments-font-weight;
                          position: relative;
                          padding: 10px 16px;
                          color: inherit;
                          font-size: 14px;
                          line-height: 16px;
                          letter-spacing: 0.02em;
                          display: flex;
                          justify-content: space-between;
                          align-items: center;
                          width: 100%;

                          .departments__link-arrow {
                            top: calc(50% - 5px);
                            fill: $departments-arrow-color;
                          }
                        }

                        .departments__menu {
                          position: fixed;
                          left: 100%;
                          top: 0;
                          height: 100%;
                          display: none;

                          .menu {
                            color: $menu-font-color;
                            background: $menu-bg;
                            box-shadow: $menu-shadow;
                            list-style: none;
                            position: absolute;
                            width: 190px;
                            padding: 4px 0;
                            font-size: 14px;
                            line-height: 16px;
                            letter-spacing: 0.02em;
                            font-weight: $font-weight-medium;
                            max-height: 368px;
                            overflow-y: auto;
                          }
                        }

                        &:hover {
                          & > .departments__menu {
                            display: block;
                          }

                          & > a {
                            background: $departments-item-hover-bg;
                          }

                          & > .departments__submenu {
                            display: block;
                            //transform: rotateY(0deg);
                            animation: fadeIn .2s
                          }

                        }

                        .departments__submenu {
                          top: 0;
                          position: fixed;
                          left: 187px;
                          //transform: rotateY(45deg);
                          //transition: transform 0.2s, opacity 0.2s;
                          display: none;
                        }
                      }
                    }
                  }
                }
              }

              .departments__button {
                gap: 8px;
                border: none;
                background: transparent;
                color: inherit;
                font-size: 16px;
                position: relative;
                z-index: 1;
                width: 100%;
                height: 100%;
                font-weight: $font-weight-medium;
                padding: 0;
                display: flex;
                align-items: center;
                cursor: pointer;

                .category-title-fms {
                  color: #303030;
                  font-size: 14px;
                }

                svg {
                  width: 16px;
                  height: 16px;
                }
              }

              &.departments--opened {
                .departments__links-wrapper {
                  overflow: visible;
                  opacity: 1;

                  .departments__links {
                    width: 294px;
                    display: block;
                    border: 1px solid #f5f4f4;
                    border-radius: 4px;
                    max-height: 425px;
                    overflow-x: hidden;
                    overflow-y: auto;
                  }
                }
              }
            }
          }

          .site-header___search {
            flex-grow: 1;
            display: flex;
            padding-left: 10px;
            align-items: center;
            justify-content: flex-end;

            .site-header__search {
              display: flex;
              flex-grow: unset;
              justify-content: flex-end;
              width: 100%;
            }
          }

          .nav-panel__indicators {
            width: auto;
            display: flex;
            height: 100%;
            justify-content: space-between;
            align-items: center;
            gap: 20px;
            padding-left: 22px;

            .nav-panel__item_row {
              .indicator {
                cursor: pointer;
                display: flex;
                flex-direction: column;
                align-items: center;

                &.account-logo-svg {
                  fill: #ffffff;
                }

                .indicator__button {
                  display: flex;
                  align-items: center;
                  border: none;
                  background: transparent;
                  cursor: pointer;
                  padding: 0;
                  color: inherit;
                  fill: #fff;
                  flex-direction: column;

                  &:focus {
                    outline: none;
                  }

                  &:hover {
                    color: inherit;
                  }

                  .indicator__area {
                    background: $indicator-default-bg;
                    transition: background-color 0.2s;
                    border-radius: $indicator-border-radius;
                    height: 25px;
                    display: flex;
                    position: relative;
                    align-items: center;

                    svg {
                      width: 24px;
                      height: 25px;

                      path {
                        stroke: #000000;
                      }
                    }

                    .indicator__value {
                      height: 15px;
                      width: 15px;
                      font-size: 9px;
                      border-radius: 50%;
                      position: absolute;
                      background: #FFD12F;
                      color: #353535;
                      font-weight: 700;
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      top: -7px;
                      right: -8px;
                      padding-top: 2px;
                      padding-left: 1px;
                    }

                    &.desktop_user_active__wrapper {
                      rect {
                        fill: white !important;
                      }

                      path {
                        stroke: $main-theme-color-dark !important;
                      }

                      circle {
                        stroke: $main-theme-color-dark !important;
                      }
                    }
                  }
                }

                .indicator-title-fms {
                  display: none;
                }

                .indicator__dropdown {
                  position: absolute;
                  visibility: hidden;
                  transform-origin: top;
                  transform: rotateX(60deg);
                  opacity: 0;
                  transition: transform 0.2s,
                  opacity 0.2s,
                  visibility 0s 0.2s;
                  top: 100%;
                  bottom: unset;
                  right: 0;

                  .indicator {
                    flex-direction: column;
                    align-items: center;

                    .account-menu {
                      box-shadow: 0 5px 10px rgb(0 0 0 / 10%);
                      background: #fff;
                      color: $light-opposite-color;
                      width: 280px;
                      overflow-y: auto;
                      overscroll-behavior-y: contain;
                      -webkit-overflow-scrolling: touch;
                      border-bottom-right-radius: 10px;
                      border-bottom-left-radius: 10px;

                      .account-menu__form {
                        padding: 0 30px 32px;

                        .account-menu__form-title {
                          text-align: center;
                          padding: 20px 0 6px;
                          font-weight: 700;
                          font-size: 30px;
                        }

                        .account-menu_loginAndPass {
                          display: flex;
                          flex-direction: column;
                          margin-top: 20px;

                          input {
                            padding: 12px;
                          }

                          a {
                            font-size: 12px;
                            line-height: 16px;
                            text-transform: capitalize;
                            color: $body-font-color;
                            margin-top: 8px;
                            text-decoration: underline;
                          }
                        }

                        .account-menu__form-button {
                          margin-top: 32px;
                          text-align: center;
                          margin-bottom: 1rem;

                          .rounded-pills {
                            color: white;
                            width: 100%;
                            border-radius: 5px;
                            text-align: center;
                            padding: 6px 28px;
                          }
                        }

                        .account-menu__form-link {
                          font-size: 14px;
                          text-align: center;
                          margin-left: unset;
                          color: $body-font-color;

                          .new-customer {
                            font-size: 14px;
                            text-align: center;
                            color: #2f2f2f;
                            margin-right: 10px;
                          }

                          a {
                            transition: color 0.1s;
                            font-size: 12px;
                            line-height: 16px;
                            text-transform: capitalize;
                            color: $body-font-color;
                            margin-top: 8px;
                            text-decoration-line: underline;
                          }

                          a:hover {
                            background: none;
                            color: #000C66;
                            text-decoration: underline;
                          }

                          a:active {
                            background: none;
                            color: #121212;
                          }
                        }
                      }
                    }

                    .dropcart {
                      font-size: 15px;
                      width: 320px;
                      font-weight: $font-weight-normal;
                      color: $dropcart-font-color;
                      background: $dropcart-bg;
                      box-shadow: $dropcart-shadow;

                      .dropcart__products-list ,
                      .dropcart__products-list_scroll{
                        padding: 20px 16px;
                        //TODO !!! after hotfix check and remove
                        //min-height: max-content;
                        max-height: calc(100% - 330px);
                        display: flex;
                        flex-direction: column;
                        gap: 24px;

                        .dropcart__product {
                          display: flex;

                          .dropcart__product-image {
                            width: 56px;
                            flex-shrink: 0;
                            margin-right: 14px;
                          }

                          .dropcart__product-info {
                            margin: 0;
                            flex-grow: 1;

                            .dropcart__product-name {
                              text-align: left;
                              margin-top: -2px;
                              line-height: 18px;
                              font-size: 15px;

                              a {
                                transition: .15s;
                              }
                            }

                            .dropcart__product-meta {
                              margin-top: 5px;
                              font-size: 13px;
                              display: flex;
                              align-items: center;
                              gap: 5px;

                              .dropcart__product-price {
                                font-size: 14px;
                                font-weight: 700;
                              }
                            }
                          }

                          .dropcart__product-remove {
                            margin-top: -6px;
                            flex-shrink: 0;
                            cursor: pointer;

                            path {
                              stroke: $header-icon-fill-stroke-color;
                              fill: black;
                            }
                          }
                        }
                      }

                      .dropcart__products-list_scroll {
                        padding: 20px 16px;
                        text-align: center;
                        //TODO !!! after hotfix check and remove
                        //min-height: max-content;
                        max-height: 290px;
                        overflow-y: scroll;
                      }

                      .dropcart__totals {
                        border-top: 1px solid $dropcart-divider-color;
                        line-height: 24px;
                        padding: 16px 20px 0;

                        table {
                          width: 100%;
                        }

                        tr {
                          display: flex;
                          justify-content: space-between;
                          align-items: flex-end;
                        }
                      }

                      .dropcart__buttons {
                        display: grid;
                        grid-gap: 10px;
                        grid-template-columns: 1fr 1fr;
                        margin-top: 4px;
                        padding: 20px;
                        justify-content: space-between;

                        .dropcart__buttons-link {
                          white-space: nowrap;
                          overflow: hidden;
                        }

                        a {
                          font-size: 15px;
                          width: 135px;
                          display: block;
                          color: #fff;
                          border-radius: 60px !important;
                          text-align: center;
                        }
                      }

                      .dropcart__empty{
                        padding: 42px 30px;
                        text-align: center;
                        min-height: max-content;
                        max-height: 290px;
                        overflow-y: auto;
                      }
                    }
                  }
                }
              }

              .indicator--trigger--click.indicator--opened,
              .indicator--trigger--hover:hover {
                .indicator__dropdown {
                  z-index: 1;
                  transition-delay: 0s, 0s, 0s;
                  opacity: 1;
                  visibility: visible;
                  transform: rotateX(0deg);
                }
              }
            }

            .nav-panel__item-custom {
              position: relative;

              .nav-panel__account-nav {
                position: absolute;
                right: 50%;
                transform: translateX(50%);
                width: 260px;
                padding-top: 10px;
                top: 100%;
                display: none;

                & > div {
                  background-color: #fff;
                  width: 100%;
                  box-shadow: 4px 4px 9px 1px rgba(0,0,0,0.15);
                }

                ul {
                  padding-bottom: 0;
                  border-radius: 0;
                }
              }

              &:hover .nav-panel__account-nav,
              &:hover .nav-panel__account-nav:hover,
              .nav-panel__account-nav:hover {
                display: flex;
              }

              .account-nav__item {
                cursor: pointer;
                padding: 11.5px 10px;
                margin: 4px 10px;

                &:hover {
                  background-color: #f7f7f7;
                  text-decoration: underline;
                }
                a {
                  font-weight: 400;
                  font-size: 14px;
                  color: #000;
                  display: flex;
                  align-items: center;
                  gap: 5px;

                  span {
                    display: flex;
                    width: 30px;
                  }
                }

                &:last-child {
                  border-top: 1px solid #ddd;
                }

                .logout {
                  color: red;
                }
              }
              .account-nav__item--active {
                background-color: #f7f7f7
              }
            }
          }
        }
      }
    }
  }

  .mobile__search {
    display: none;
  }
}

.site-header__search{
  display: flex;
}

@media screen and (max-width: 991px) {
  .header {
    height: $nav-panel-height !important;
    background: #FFF;
    box-shadow: 0 6px 11px 0 rgba(0, 0, 0, 0.10);
    border-bottom: 2px solid $main-theme-color-dark;

    .first_section {
      height: 100%;

      .mobile-header__menu-button {
        flex-shrink: 0;
        width: 36px;
        height: 36px;
        border-radius: $mobile-header-menu-button-border-radius;
        background: $mobile-header-menu-button-default-bg;
        fill: $mobile-header-menu-button-default-color;
        border: none;
        padding: 0;
        cursor: pointer;
        transition: fill 0.2s, background 0.2s;
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
          fill: $main-theme-color-dark;

          path {
            stroke: black;
          }
        }

        &:focus {
          outline: none;
        }

        &:focus,
        &:hover {
          background: $mobile-header-menu-button-hover-bg;
          fill: $mobile-header-menu-button-hover-color;
        }
      }

      .logo_div {
        height: 40px !important;

        @media (max-width: 320px) {
          height: 20px !important;
          justify-content: center;
        }

        a {
          height: 100% !important;

          img {
            object-position: center !important;
            height: 100% !important;
            object-fit: contain !important;
          }
        }

        span {
          height: 100% !important;
        }
      }

      .language-button {
        min-height: unset !important;
        display: flex;
        width: 40px;
        justify-content: center;
        padding: 0 5px;
        position: relative;

        .menu-none {
          display: none;
        }

        .menu-block {
          z-index: 200;
          transition-delay: 0s, 0s, 0s;
          opacity: 1;
          visibility: visible;
          position: absolute !important;
          top: 100%;
          right: 0;
          width: 100%;
          padding: 0;

          .menu {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 10px;
            margin: 0;
            padding: 10px 5px;
            color: #353535;
            background: #fff;

            .menu_item {
              &:hover {
                background: $menu-item-hover-bg;
              }

              .menu__icon {
                display: flex;
                align-items: center;
              }
            }
          }
        }
      }

      .header_nav_menu {
        display: none;
      }
    }

    .second_section {
      height: unset;

      .nav-panel{
        box-shadow: none;
        color: unset;

        .nav-panel__container{
          height: unset;

          .nav-panel__row{
            position: unset;

            .nav-panel__departments{
              display: none;
            }

            .site-header__search{
              position: absolute;
              top: 0;
              left: 0;
              display: none;
              width: 100%;
              height: 36px;
              pointer-events: unset;
              padding: 0;

              :global(.customSearch_search--location--header__Y_50Y){
                :global(.customSearch_search__body__ohKgq){
                  :global(.customSearch_search__form__YMVW5){
                    :global(.customSearch_search__input__23_XT){
                      border: none;
                      background: #FFF;
                    }
                  }
                }
              }

              &.mobile-header__search {
                //height: 60px;
                top: 60px;
                left: 0;
                width: 100%;
                position: absolute;
                background: $offcanvas-search-bg;
                box-shadow: $offcanvas-search-shadow;
                z-index: 1;
                transform: translateY(-54px);
                visibility: hidden;
                opacity: 0;
                transition: transform 0.3s ease-in-out, visibility 0s 0.3s, opacity 0.3s;
                display: flex;
                pointer-events: none;

                @media screen and (max-width: 768px) {
                  top: 0;
                  height: 60px;
                }
              }

              &.mobile-header__search--open {
                pointer-events: auto;
                transition-delay: 0s, 0s;
                transform: translateY(0);
                visibility: visible;
                opacity: 1;
              }
            }

            .nav-panel__indicators{
              display: none;
            }
          }
        }
      }
    }
  }

  :global(.customFooter_bottom-nav-item__3prmU) {
    .indicator--trigger--click {
      cursor: pointer;
      display: flex;

      .indicator__button {
        display: flex;
        align-items: center;
        border: none;
        background: transparent;
        cursor: pointer;
        padding: 0;
        color: inherit;
        fill: #fff;

        .indicator__area {
          background: transparent;
          transition: background-color .2s;
          border-radius: 2px;
          display: flex;
          position: relative;
          align-items: center;

          svg {
            width: 24px;
            height: 25px;
          }

          .indicator__value {
            height: 15px;
            width: 15px;
            font-size: 9px;
            border-radius: 50%;
            position: absolute;
            background: #fff;
            color: #353535;
            font-weight: 700;
            display: flex;
            align-items: center;
            justify-content: center;
            top: -7px;
            right: -8px;
          }

          .mobile_indicator__value {
            background: $main-theme-color-dark !important;
            color: white;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .header {
    .mobile-header__search {
      top: 0;
      left: 0;
      width: 100%;
      height: 60px;
      position: absolute;
      background: $offcanvas-search-bg;
      box-shadow: $offcanvas-search-shadow;
      z-index: 1;
      transform: translateY(-54px);
      visibility: hidden;
      opacity: 0;
      transition: transform 0.3s ease-in-out, visibility 0s 0.3s, opacity 0.3s;
      display: flex;
      pointer-events: none;
    }

    .mobile-header__search--open {
      pointer-events: auto;
      transition-delay: 0s, 0s;
      transform: translateY(0);
      visibility: visible;
      opacity: 1;
    }
  }
}

