@import "../../../../scss/variables";

.empty__footer {
  padding: 0 0 80px !important;
  margin-top: 0 !important;
  border: none !important;
  background: none !important;
}

.footer_padding_off {
  padding-top: 0 !important;
}
.copyright_border_none {
  border-top: unset !important;
}

.site-footer {
  padding: 34px 0 16px;
  background-color: #faf7f7;

  .footer-container {
    width: 100%;
    height: 100%;
    margin-left: auto;
    margin-right: auto;

    .footer-body {
      display: flex;
      justify-content: space-between;
      width: 100%;
      min-height: 134px;
      padding-bottom: 32px;
      color: #303030;
      gap: 20px;

      .footer-newsletter__title {
        color: #303030 !important;
        font-size: 20px;
        font-weight: 600;
        min-height: 55px;
        margin-bottom: 24px !important;
      }

      .footer-main-info {
        @media(max-width: 991px) {
          display: none;
        }

        display: flex;
        flex-direction: column;
        width: calc((100% - 360px) / 3);

        div {
          margin-bottom: 16px;
        }

        .footer_logo {
          min-width: 200px;
          display: flex;
          align-items: center;
          margin-bottom: 24px;

          img {
            object-fit: contain;
            object-position: left;
            @media only screen and (max-width: 991px) {
              object-position: center;
            }
          }
        }

        .footer_text {
          p {
            font-size: 14px;
            font-weight: 300;
            max-width: 200px;
          }
        }

        .footerLinks-fms,
        .trackingNumber-fms {
          a {
            color: #303030;
            font-size: 16px;
          }
        }
      }

      .footer-first-column,
      .footer-newsletter-hide {
        width: calc((100% - 360px) / 3);
        max-width: calc((100% - 300px) / 2);
      }

      .footer-first-column {
        div {
          font-size: 16px;
          line-height: 20px;
          margin-bottom: 16px;

          a {
            color: #303030;
            font-size: 16px;
            line-height: 19px;
            text-transform: capitalize;
          }
        }
      }

      .footer-newsletter-hide {
        .footer-contacts {
          .footer-contacts__contacts {
            font-size: 15px;
            list-style: none;
            padding: 0;
            text-align: left;

            a {
              color: $body-font-color;
            }

            .contactsAddress_list {
              list-style: none;

              .contactsAddress {
                white-space: pre-line;
                padding: 0;
                color: #303030;
                font-size: 16px;
                line-height: 19px;
                margin-bottom: 16px;
              }
            }

            .footer-newsletter__social-links {
              display: flex;

              .social-links__list {
                list-style: none;
                padding: 0;
                display: flex;
                flex-wrap: wrap;
                gap: 12px;

                .social-links__item {
                  .social-links__link {
                    span {
                      position: relative !important;
                      width: 30px !important;
                      height: 30px !important;
                    }
                  }
                }
              }
            }
          }
        }
      }

      .newsletter-block {
        width: 300px;
        max-width: 300px;

        .footer-newsletter-container {

          .footer-newsletter__form {
            display: flex;
            flex-direction: column;

            input {
              border-radius: 60px !important;
            }

            .footer-newsletter__form-button {
              width: 100%;
              border-radius: 30px;
              margin-top: 15px;
              color: #fff;
              background: $btn-primary-bg-color;
              font-size: 18px;
              text-overflow: ellipsis;
              overflow: hidden;
              white-space: nowrap;
              border: none;

              &:hover {
                background-color: $btn-primary-hover-bg-color;
                transition: all 0.1s ease-in-out;
                color: white;
              }

              &:focus {
                color: white;
              }
            }
          }
        }
      }
    }

    .copyright {
      display: flex;
      justify-content: space-between;
      align-items: center;
      text-align: center;
      padding-top: 16px;
      border-top: 1px solid #DEDEDE;

      a {
        text-decoration: underline;
      }
    }
  }

  .toTop {
    position: relative;
    z-index: 9;
    pointer-events: none;
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.3s, visibility 0s 0.3s;

    &.toTop--show {
      visibility: visible;
      opacity: 1;
      transition-delay: 0s, 0s;
    }

    .toTop__body {
      position: fixed;
      bottom: 36px;
      right: calc((100% - (1260px + 70px)) / 2);
      top: auto;
      display: flex;
      margin-top: -(ceil(36px / 2));

      .toTop__end {
        position: relative;
        display: flex;
        justify-content: flex-start;
        flex-direction: row-reverse;

        &:before {
          content: "";
          flex-grow: 1;
        }

        .toTop__button {
          pointer-events: auto;
          width: 39px;
          height: 39px;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 0;
          border: none;
          cursor: pointer;
          color: $btn-primary-font-color;
          background: $main-theme-color-dark;
          fill: currentColor;
          flex-shrink: 0;
          transition: background-color 0.15s, color 0.15s;

          svg {
            margin-top: -2px;
            margin-bottom: 1px;
          }
        }
      }

    }
  }
}

.bottom_footer {
  display: none;
}

.empty_menus {
  display: none;
}

.empty_contacts {
  display: none;
}

@media screen and (max-width: 991px) {
  .site-footer {
    width: 100%;
    height: max-content;
    margin-top: 16px;
    border-top: 1px solid #ebebeb;
    padding: 30px 0 100px;
    background-color: #faf7f7;

    .footer-container {
      .footer-body {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: column;
        gap: 15px;

        .footer-first-column,
        .footer-newsletter-hide {
          display: none;
        }

        .newsletter-block {
          width: 100%;

          .footer-newsletter-container {
            .footer-newsletter__title {
              color: #2c2c2c;
              font-size: 18px;
              text-align: center;
              margin: 0 !important;
            }

            .footer-newsletter__form {
              display: flex;
              flex-direction: column;

              .footer-newsletter__form-button {
                font-size: 16px;
                max-width: 100%;
                width: 100%;
                margin-bottom: 15px;
              }
            }
          }
        }
      }

      .copyright {
        font-weight: 300;
        font-size: 12px;
        line-height: 18px;
        color: #303030;
        justify-content: center;
        flex-direction: column;
      }
    }
  }

  .bottom_footer {
    display: block;
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 64px;
    z-index: 1000;
    transition: ease-in-out .3s;

    .bottom_search {
      width: 64px;
      height: 64px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      background: $main-theme-color-light;
      box-shadow: 0 0 4px white;
      position: absolute;
      left: calc(50% - 32px);
      top: -32px;
      z-index: 9;

      .click-disabled {
        path {
          fill: white !important;
        }
      }

      @media screen and (max-width: 425px) {
        width: 64px;
        height: 64px;
        left: calc(50% - 32px);
        top: -32px;
      }

      :global(.customHeader_indicator__button__uvRhN) {
        display: flex;
        align-items: center;
        border: none;
        background: transparent;
        cursor: pointer;
        padding: 0;
        color: inherit;
        fill: #fff;
        pointer-events: none;

        :global(.customHeader_indicator__area__gmkwn) {
          background: transparent;
          transition: background-color .2s;
          border-radius: 2px;
          height: 25px;
          display: flex;
          position: relative;
          align-items: center;

          path {
            fill: #FFF;
          }
        }
      }

    }

    .bottomNavigation-wrapper {
      width: 100%;
      height: 64px;
      display: flex;
      position: fixed;
      bottom: 0;
      right: 0;
      background-color: #fff;
      z-index: 1;
      border-top: 1px solid #ebebeb;
      -webkit-mask: radial-gradient(37px at 50% -1px, #0000 98%, #000);
      overflow: hidden;

      .bottom-nav-item {
        width: 20% !important;
        position: relative;
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100%;
        transition: all 0.3s ease;

        &.local-item-active {
          background: transparent !important;
        }

        .indicator {
          .indicator__area {
            padding: 0;
          }
        }

        .under_line__active {
          position: absolute;
          bottom: 0;
          height: 6px;
          width: 100%;
          background: $main-theme-color-dark;
        }
      }

      circle {
        stroke: $main-theme-color-light;
      }

      .user_indicator {
        path {
          stroke: #121212;
        }

        circle {
          stroke: #121212;
        }
      }

      .local-item-active {
        background: transparent !important;
      }
    }

  }
}

@media screen and (max-width: 768px) {
  .site-footer {
    .footer-container {
      .footer-body {
        .newsletter-block {
          .footer-newsletter-container {
            .footer-newsletter__title {
              text-align: center;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 425px) {
  .site-footer {
    .footer-container {
      .footer-body {
        min-height: unset;
        padding-bottom: unset;
        margin-bottom: 15px;
        .newsletter-block {
          padding: 0 16px;
        }
      }
    }
  }
}