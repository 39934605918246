@import "../../../../../scss/variables";


@media only screen and (max-width: 991px) {
  .show-search-second {
    display: block;
  }
  .hide-search-second {
    display: none;
  }
}

.search--location--header {
  max-width: 411px;
  min-width: 200px;
  width: 100%;
  margin: 0;

  .search__body {
    position: relative;
    z-index: 10;

    .search__form {
      height: 34px;
      display: flex;

      .search__input {
        border-radius: 50px;
        padding: 0 40px 0 20px;
        background: #fff;
        width: 1px;
        flex-grow: 1;
        font-size: 15px;
        transition: color .15s;
        color: #8f8f8f;
        border: .5px solid #b7b7b7;

        &:focus-visible {
          outline: none;
        }

        &::placeholder {
          color: $header-search-input-default-placeholder;

          padding-left: 0;
        }

        & ~ .search__border {
          background: $header-search-input-default-bg;
          box-shadow: $header-search-input-default-shadow;
        }
      }

      .search__button {
        position: absolute;
        right: 5px;
        top: -5px;
        transform: translate(-10px, 75%);
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
        border-style: none;
        padding: 0;
        background: #fff;
        flex-grow: 0;
        width: auto;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 2px;
        transition: fill 0.15s;

        &:hover,
        &:focus {
          outline: none;
          fill: #333;
        }
      }

      .search__button--type--close{
        display: none;
      }
    }

    .suggestions::-webkit-scrollbar-thumb {
      border-radius: 1px;
    }

    .suggestions {
      z-index: -1;
      position: absolute;
      box-shadow: $header-search-suggestions-shadow;
      background: $header-search-suggestions-bg;
      top: 15px;
      width: 100%;
      padding-top: 34px;
      padding-bottom: 10px;
      border-radius: 2px;
      visibility: hidden;
      opacity: 0;
      pointer-events: none;
      max-height: 500px;
      overflow: auto;
      transition: opacity 0.2s,
      visibility 0s 0.2s;
      color: $header-search-suggestions-font-color;

      @media(min-width: 991px) {
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
      }

      .suggestions__item:hover:before {
        background: $header-search-suggestions-item-hover-bg;
      }

      .suggestions__item-meta {
        color: $header-search-suggestions-muted-font-color;
      }

      .suggestions__list {
        list-style: none;
        margin: 0;
        padding: 0;
        font-size: 15px;
        line-height: 19px;

        .suggestions__item {
          gap: 10px;
          padding: 10px;
          display: flex;
          cursor: pointer;
          position: relative;
          align-items: center;

          &:before {
            z-index: 1;
            position: absolute;
            content: '';
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            pointer-events: none;
          }

          .suggestions__item-image {
            flex-shrink: 0;
            width: 50px;
            height: 50px;
            position: relative;
          }

          .suggestions__item-info {
            flex-grow: 1;
            padding: 3px 0 2px;

            .suggestions__item-meta {
              margin-top: 1px;
              font-size: 13px;
              line-height: 15px;
              color: $header-search-suggestions-muted-font-color;
            }
          }

          .suggestions__item-price {
            white-space: nowrap;
            flex-shrink: 0;
            padding: 3px 0 2px;
            font-weight: $font-weight-bold;
            font-size: 14px;
            margin-left: 12px;
          }
        }

        .search__fm_dr_Message{
          text-align: center;
          font-size: 20px;
          padding-top: 10px;
          padding-bottom: 10px;
        }
      }
    }
  }
}

.search--has-suggestions.search--suggestions-open {
  .search__suggestions {
    transition-delay: 0s, 0s;
    opacity: 1;
    visibility: visible;
    pointer-events: auto;
  }
}

@media screen and (max-width: 991px) {
  .search--location--header {
    width: 100%;
    max-width: unset;
    height: 100%;
    margin: 0;

    .search__body {
      width: 100%;

      .search__form {
        height: 36px;
        display: flex;

        .search__input {
          border-radius: unset;
          border-bottom: 1px solid #ebebeb;
        }

        .search__button--type--close{
          display: none;
        }
      }

      .search__suggestions {
        position: unset;
        border-top: 1px solid rgba(0, 0, 0, .08);
        padding: 6px 0;
        overflow-y: auto;
        overscroll-behavior-y: contain;
        background: #fff;
        width: 100%;

        .suggestions__list {
          .suggestions__item {
            .suggestions__item-actions {
              display: none;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .search--location--header{
    margin: 0;

    .search__body {
      width: 100%;
      display: flex;
      flex-direction: column;

      .search__form {
        display: flex;
        height: 60px;
        flex-shrink: 0;

        .search__input {
          flex-grow: 1;
          flex-basis: 0;
          width: 0;
          background: transparent;
          border: none;
          padding-left: 15px;

          &:focus {
            outline: none;
          }

          &::placeholder {
            color: $offcanvas-search-placeholder-color;
          }
        }

        .search__button {
          flex-shrink: 0;
          background: transparent;
          border: none;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          fill: $offcanvas-search-button-default-color;
          top: 5px;

          &:focus {
            outline: none;
          }

          &:focus,
          &:hover {
            fill: $offcanvas-search-button-hover-color;
          }

          &.search__button--type--close{
            display: flex;
          }

          &.search__button--type--submit{
          display: none;
        }
        }
      }
    }
  }

  .mobilemenu__content {
    margin: 0 10px 80px;
  }

  .mob-links__item {
    padding: 18px 5px;
    margin: 0;
  }
}